<template>
  <div>
    <v-container>
      <br/><br/>

      <v-row>
        <v-col cols="12">
          <br/>
          <p v-if="popup" @click="showModal('coudiere')">Quelques exemples de coudières en matière daim que
            l'on peut vous proposer.
            <v-icon color="black darken-2" dark small>
              mdi-information
            </v-icon>
          </p>

        </v-col>
      </v-row>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-row>
          <v-col cols="12">
            <div class="spacer"></div>
            <v-radio-group v-model="coudiere_couleur" :rules="requireRules">
              <v-radio
                v-for="(label, index) in labels"
                :key="index"
                :label="label.label"
                :value="label.value"
                @click="getPrice()"
              />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <image-preview
              :is-bonus-eligible="bonus > 0"
              :value="pictures"
              @change="v => pictures = v"
            />
          </v-col>
          <price :amount="price" :bonus="bonus" :display-bottom-line="!!coudiere_couleur"/>
        </v-row>
        <bonus-image-confirm
          v-if="coudiere_couleur && bonus > 0 && pictures.length < 2"
          @skip-bonus="bonus = 0"
        />
        <confirm-flow
          v-else-if="coudiere_couleur && !this.flux.indexEditProduit"
          @finalize="noarticle"
          @new-repair="onSelectNewRepair"
          @new-article="noarticle(false)"
        />
        <v-row v-else-if="this.flux.indexEditProduit">
          <v-col v-if="error" cols="12">
            <div class="text-center" style="color:#FF0000">Vous devez effectuer un choix de coudières</div>
          </v-col>
          <v-col class="text-center" cols="12">
            <div class="btn btn-1 text-center btn-size" @click="noarticle">MODIFIER</div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <span style="display:none">{{ flux.actionId }}</span>
  </div>
</template>
<script>
import trouPrice from '../../assets/data/price/coudieres.json';

import {mapMutations, mapState} from 'vuex'
import Price from "@/components/shared/Price.vue";
import ImagePreview from "@/components/interface/ImagePreview.vue";
import BonusImageConfirm from "@/components/shared/BonusImageConfirm.vue";
import ConfirmFlow from "@/components/shared/ConfirmFlow.vue";

let liste_reparations = []

export default {
  data() {
    return {
      labels: [],
      price: 0,
      bonus: 0,
      pictures: [],
      coudiere_couleur: null,
      popup: true,
      error: false,
      requireRules: [
        v => !!v || 'Ce champs est requis'
      ]
    }
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('flux-app', ['flux']),
  },
  created() {
    if (this.flux.activeTab !== 'coudieres') {
      return null;
    }
    this.labels = trouPrice[0][this.flux.level2].labels

    if (this.flux.level2 && this.flux.verrou === false && this.flux.indexEditProduit !== null) {
      this.setVerrou(true)
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
      console.log(this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation])
      this.bonus = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]?.bonus?.[this.flux.isDouble ? 'double' : 'non_double'] ?? 0
      this.coudiere_couleur = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['coudiere_couleur']

    }
  },
  updated() {
    if (this.flux.activeTab !== 'coudieres') {
      return null;
    }
    if (this.flux.level2) {
      this.labels = trouPrice[0][this.flux.level2].labels
      this.tabPromotions = trouPrice[0][this.flux.level2].promotions
    }

    if (this.flux.level2 && this.flux.verrou === false && this.flux.indexEditProduit !== null) {
      this.setVerrou(true)
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
      this.bonus = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]?.bonus?.[this.flux.isDouble ? 'double' : 'non_double'] ?? 0
      console.log(this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation])
      this.coudiere_couleur = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['coudiere_couleur']
    }
  },
  methods: {
    ...mapMutations('flux-app', ['setConstat', 'setcptProduct', 'setcptReparation', 'setVerrou', 'setLevel1', 'setLevel2', 'setLevel3', 'setComponentName']),
    ...mapMutations('cart-app', ['addProduct', 'getTotalCart']),
    noarticle(showCart = true) {
      if (this.price <= 0) {
        this.error = true;
      } else if (this.$refs.form.validate()) {
        if (this.flux.indexEditProduit !== null) { //Mode Edit
          this.editProduct();
          this.setLevel1(null)
          this.setLevel2(null)
          this.setLevel3(null)
          this.setComponentName(null)
        } else {
          this.storeProduct()
          this.setcptProduct(this.flux.cptProduct + 1)
          liste_reparations = []
          this.setcptReparation(0)
        }

        this.$emit('evenement', 'scroll1')

        if (showCart) {
          this.setConstat('cart')
          this.$emit('showmodal', 'cart')
        }
      }
    },
    onSelectNewRepair() {
      this.storeProduct()
      this.$emit('evenement', 'scroll3')
    },
    editProduct() {
      liste_reparations = this.products[this.flux.indexEditProduit].reparations
      //On check l'historique des réparations
      let index = liste_reparations.findIndex((obj => obj.id == (this.flux.indexEditReparation + 1)));
      if (index > -1) {
        let reparation = {
          id: liste_reparations[index].id,
          activeTab: 'coudieres',
          type: 'coudieres',
          componentName: 'trou-accroc-dechirure',
          isCursor: this.isCursor,
          qte: 1,
          coudiere_couleur: this.coudiere_couleur,
          price: this.price,
          bonus: this.pictures.length > 1 ? this.bonus : 0,
          pictures: this.pictures
        }

        console.log(reparation)

        this.products[this.flux.indexEditProduit].reparations.splice(index, 1, reparation)
        let data = {
          idLevel1: this.products[this.flux.indexEditProduit].idLevel1,
          level1: this.products[this.flux.indexEditProduit].level1,
          level2: this.products[this.flux.indexEditProduit].level2,
          level3: this.products[this.flux.indexEditProduit].level3,
          isDouble: this.products[this.flux.indexEditProduit].isDouble,
          id: this.products[this.flux.indexEditProduit].id,
          name: this.products[this.flux.indexEditProduit].name,
          famille: this.products[this.flux.indexEditProduit].famille,
          reparations: liste_reparations
        }


        this.addProduct(data)
        this.getTotalCart(this.products)
        this.$emit('evenement', 'scroll3')

      }
    },
    storeProduct() {
      if (this.price <= 0) {
        this.error = true;
      } else if (this.$refs.form.validate()) {
        this.setcptReparation(this.flux.cptReparation + 1)

        if (this.flux.cptProduct < 1) {
          this.setcptProduct(this.flux.cptProduct + 1)
        }

        //On check l'historique des réparations
        liste_reparations = []
        let index = this.products.findIndex((obj => obj.id == this.flux.cptProduct));
        if (index > -1) {
          liste_reparations = this.products[index].reparations
        }

        let reparation = {
          id: this.flux.cptReparation,
          activeTab: this.flux.activeTab,
          type: 'coudieres',
          componentName: 'trou-accroc-dechirure',
          isCursor: this.isCursor,
          qte: 1,
          coudiere_couleur: this.coudiere_couleur,
          price: this.price,
          bonus: this.pictures.length > 1 ? this.bonus : 0,
          pictures: this.pictures
        }

        liste_reparations.push(reparation)

        let data = {
          idLevel1: this.flux.idLevel1,
          level1: this.flux.level1,
          level2: this.flux.level2,
          level3: this.flux.level3,
          isDouble: this.flux.isDouble,
          id: this.flux.cptProduct,
          name: this.flux.level2,
          famille: this.flux.level1,
          reparations: liste_reparations
        }
        this.addProduct(data)
        this.getTotalCart(this.products)
        this.setLevel3(null)
        this.setComponentName(null)
      }
    },
    resetPrice() {
      this.price = 0
    },
    getPrice() {
      if (this.isCursor === true) {
        this.price = trouPrice[1]['curseur']
      } else {
        this.price = trouPrice[0][this.flux.level2]['prix']
        this.bonus = trouPrice[0][this.flux.level2]?.bonus?.[this.flux.isDouble ? 'double' : 'non_double'] ?? 0
      }
      if (this.flux.isDouble) {
        this.price = this.price * 1.2
      }
      this.price = Math.round(this.price * 100) / 100
      this.error = false

    },
    showModal(constat) {
      this.setConstat(constat)
      this.$emit('showmodal')
    }
  },
  components: {ConfirmFlow, BonusImageConfirm, ImagePreview, Price}
}
</script>
