<template>
  <v-row>
    <v-col cols="12">
      <div class="text-center">
        <b>
          Les photos sont obligatoires pour bénéficier du bonus. Veuillez ajouter les photos manquantes ou continuer
          sans bonus.
        </b>
      </div>
    </v-col>
    <v-col class="text-center" cols="12">
      <div class="btn btn-light text-center btn-size" @click="$emit('skip-bonus')">
        Je continue sans bénéficier du bonus
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BonusImageConfirm"
}
</script>
